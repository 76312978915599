// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-afspraak-maken-js": () => import("./../../../src/pages/afspraak-maken.js" /* webpackChunkName: "component---src-pages-afspraak-maken-js" */),
  "component---src-pages-angsten-js": () => import("./../../../src/pages/angsten.js" /* webpackChunkName: "component---src-pages-angsten-js" */),
  "component---src-pages-brainspotting-js": () => import("./../../../src/pages/brainspotting.js" /* webpackChunkName: "component---src-pages-brainspotting-js" */),
  "component---src-pages-burn-out-js": () => import("./../../../src/pages/burn-out.js" /* webpackChunkName: "component---src-pages-burn-out-js" */),
  "component---src-pages-hartcoherentie-js": () => import("./../../../src/pages/hartcoherentie.js" /* webpackChunkName: "component---src-pages-hartcoherentie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opleidingen-js": () => import("./../../../src/pages/opleidingen.js" /* webpackChunkName: "component---src-pages-opleidingen-js" */),
  "component---src-pages-specialisaties-js": () => import("./../../../src/pages/specialisaties.js" /* webpackChunkName: "component---src-pages-specialisaties-js" */),
  "component---src-pages-tarieven-js": () => import("./../../../src/pages/tarieven.js" /* webpackChunkName: "component---src-pages-tarieven-js" */),
  "component---src-pages-werkwijze-js": () => import("./../../../src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */),
  "component---src-pages-zelfvertrouwen-js": () => import("./../../../src/pages/zelfvertrouwen.js" /* webpackChunkName: "component---src-pages-zelfvertrouwen-js" */)
}

