module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-141046250-5","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hilde Van Heuven - Psychotherapeut","short_name":"Hilde Van Heuven","start_url":"/","background_color":"#061e5b;","theme_color":"#061e5b;","display":"minimal-ui","icon":"src/images/hvh.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f36a2f5efc7d37f11bd08c1514604761"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
